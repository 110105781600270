import React from 'react';
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import parse from "html-react-parser"
import Banner from "../components/ant/Banner"
import OurService from './OurService';
import AboutUs from './AboutUs';
import Process from './Process';
import WhyUs from './WhyUs';
import Testimoni from './Testimoni';
import Client from './Client';
import RequestForm from './RequestForm';
import Portfolio from './Portfolio';
import {message, Spin} from 'antd';
import { enquireScreen } from 'enquire-js';

const HomePage = ( {data:{allWpPage:{edges}, wp}} ) => {
    const pages = edges.map(x => x.page);
    const {generalSettings: { title, description }} = wp;

    const [context, setContext] = React.useState({posts: []})
    
    // React.useEffect(() => {
    //   const {pages, posts} = pathContext;
    //   setContext({pages: pages, posts: posts});
    // }, [pathContext])
    // console.log(pathContext)

    const formRef = React.useRef(null)

    const [state, setState] = React.useState({showShadow: false, isMobile: false})

    enquireScreen((b) => {
      if (state.isMobile !== b) setState({...state, isMobile: b})
    })

    const navToShadow = (e) => setState({...state, showShadow: e.mode === 'leave' });

    const getPage = (slug) => pages?.find(x => x.slug === slug) || null;
    // const getPost = 
    const executeScroll = () => formRef.current.scrollIntoView();

    return (
      <>
        {context.posts ? <Layout scrollTo={executeScroll} isHomePage={true}>
          <SEO title={title} description={description} />
          <Banner scrollTo={executeScroll} key="banner" isMobile={state.isMobile} navToShadow={navToShadow} page={getPage('banner')} />
          <OurService />
          <AboutUs />
          {/* <BlogPost page={getPage('blog')}/> */}
          <Process />
          <Portfolio {...state}/>
          <WhyUs />
          {/* <Testimoni page={getPage('testimoni')}/> */}
          <div ref={formRef}>
            <RequestForm handleSubmit={({data, success}) => {
              if (success) {
                message.success("💖 Terima kasih atas permintaan yang telah dikirimkan, kami akan 📞 anda kembali 💖", 10);
              }
            }}/>
          </div>
          <Client page={getPage('testimoni')}/>
        </Layout> : 
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
          <Spin tip="Loading..." />
        </div>
        }
      </>
    );
}

export default HomePage

export const pageQuery = graphql`
query WpPages {
  wp {
    generalSettings {
      title
      description
    }
  }
  allWpPage {
      edges {
          page: node {
              id
              title
              slug
              parentId
              content
              uri
              featuredImage {
                node {
                  id
                  sourceUrl
                  altText
                }
              }
              author {
                node {
                  avatar {
                    url
                  }
                }
              }
          }
      }
  }
}
`