import React from 'react';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import { Button } from 'antd';
import { Element } from 'rc-scroll-anim';
import BannerImage from './BannerImage';
// import { assets } from '../../data';
import parse from "html-react-parser"

class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
    scrollTo: PropTypes.func
  }
  static defaultProps = {
    className: 'banner',
  }

  render() {
    const { className, isMobile, navToShadow, page, scrollTo } = this.props;
    return (
      <Element component="section" className={`${className}-wrapper page`} onChange={navToShadow}>
        <div className={className}>
          <div className={`${className}-img-wrapper`}>
            {/* <img width="100%" src={`${assets}/image/home/intro-landscape-3a409.svg`} alt="" /> */}
            <BannerImage />
            {/* {isMobile ?
              <img width="100%" src={`${assets}/image/home/intro-landscape-3a409.svg`} alt="" />
              :
              <BannerImage />} */}
          </div>
          <QueueAnim
            type={isMobile ? 'bottom' : 'right'}
            className={`${className}-text-wrapper`}
            delay={300}
          >
            <h1 key="h1">{parse(page?.title || '')}</h1>
            <p className="main-info" key="p">
              {parse(page?.content || '')}
            </p>
            <a onClick={scrollTo} key="a">
              <Button type="primary">
                Ajukan Kerjasama
              </Button>
            </a>
          </QueueAnim>
        </div>
      </Element>
    );
  }
}

export default Banner;